<template>
  <b-form ref="billing-details-form">
    <b-form-row style="display: block">
      <b-overlay :show="showOverlay" rounded="lg" spinner-variant="primary">
        <b-col class="px-0 px-sm-4 pt-3 pt-lg-0">
          <slot name="billing-title">
            <b-card-text class="mb-0 pay-card">Pay with card</b-card-text>
          </slot>

          <b-form-group
            v-if="!hasPaymentMethod"
            id="name-input"
            class="labels mb-0"
            label-for="name"
            content-cols="12"
          >
            <template v-slot:label>
              <span class="text-danger">*</span>Name on card
            </template>
            <b-form-input
              id="name"
              placeholder="Enter name on card..."
              class="mb-2"
              v-model="(userName.value.value as string)"
              :state="userName.errorMessage.value ? false : null"
            ></b-form-input>
            <b-form-invalid-feedback id="invited-user-name-live-feedback">{{
                userName.errorMessage.value
              }}</b-form-invalid-feedback> 
          </b-form-group>

          <b-form-group
            v-if="!hasPaymentMethod"
            id="card-input"
            class="labels mb-4"
            label-for="card"
            content-cols="12"
          >
            <template v-slot:label>
              <span class="text-danger">*</span>Card information
            </template>
            <slot name="stripe">
              <stripe-element-card
                id="card"
                ref="elementRef"
                :pk="publishableKey"
                :elementStyle="cardStyle"
                @token="tokenCreated"
              />
            </slot>
          </b-form-group>

          <b-form-group label-for="card-info" class="labels pb-2" v-else>
            <template v-slot:label>
              <b-row style="margin-bottom: -0.5rem">
                <b-col class="col-6">
                  <b-card-text>Card</b-card-text>
                </b-col>
              </b-row>
            </template>

            <!-- TODO commented out due to brand, exp_month and exp_year not available on card object it should be -->
            <b-card
              id="card-info"
              class="card-info"
              no-body
              v-if="paymentDetails && paymentDetails.card"
            >
              <b-row class="card-info-text my-auto">
                <b-col class="col-auto">
                  <span class="pr-1">{{
                    $filters.capitalizeFirst(paymentDetails.card.brand)
                  }}</span>
                  <span class="pr-1"
                    ><span>&bull;&bull;&bull;&bull;</span>
                    {{ paymentDetails.card.last4 }}</span
                  >
                </b-col>
                <b-col>
                  <span class="float-right"
                    >Expires
                    {{ $filters.formatMonth(paymentDetails.card.exp_month) }}/{{
                      paymentDetails.card.exp_year
                    }}</span
                  >
                </b-col>
              </b-row>
            </b-card>
          </b-form-group>

          <b-form-group
            id="card-email"
            class="labels email mb-0"
            label="Email"
            label-for="email"
            content-cols="12"
          >
            <b-form-input
              class="mb-0"
              id="email"
              placeholder="Enter your email..."
              v-model="email"
              disabled
            ></b-form-input>
          </b-form-group>

          <b-form-group
            id="card-code"
            class="labels pt-0"
            label="Coupon"
            label-for="code"
            content-cols="12"
          >
            <b-row>
              <b-col>
                <b-form-input
                  class="mb-0 d-inline-flex"
                  id="code"
                  placeholder="Enter discount code..."
                  @focus="enterCoupon"
                  v-model="code"
                ></b-form-input>
              </b-col>
              <b-col class="pl-0">
                <!-- @vue-ignore -->
                <b-button
                  ref="couponButton"
                  class="btn coupon-button"
                  :variant="validateButton"
                  size="md"
                  :disabled="disableCouponSubmit"
                  @click="validateCoupon"
                >
                  <b-spinner v-if="validating" small label="validating">
                  </b-spinner>
                  <div v-if="validationFailed" class="confirmed-text">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      class="bi bi-x-circle bottom-space"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                      />
                      <path
                        d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
                      />
                    </svg>
                    <span class="confirmed-text-span pl-1">Invalid code</span>
                  </div>

                  <div v-if="validationConfirmed" class="confirmed-text">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      class="bi bi-check-circle bottom-space"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                      />
                      <path
                        d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"
                      />
                    </svg>
                    <span class="confirmed-text-span">Code applied</span>
                  </div>

                  {{ couponButtonTitle }}</b-button
                >
              </b-col>
            </b-row>
            <b-row v-if="validationConfirmed">
              <b-col>
                <label class="text-muted confirm-label mb-0 text-left"
                  >Your invoice will reflect the discounted price</label
                >
              </b-col>
            </b-row>
          </b-form-group>

          <b-container class="p-0 m-0 mb-1" v-if="cardAction === 'save'">
            <b-row>
              <b-col>
                <label
                  class="text-muted confirm-label text-left"
                  style="margin-top: -1.25rem"
                  >By saving this payment method, you allow Authentic Labs to
                  charge your card for future payments in accordance with their
                  terms. This card will become your default payment method.
                  Default payment methods can be changed under billing
                  summary.</label
                >
              </b-col>
            </b-row>
            <b-row no-gutters class="pt-4" :class="newCardClass">
              <b-col class="text-right">
                <b-button-group>
                  <b-button
                    pill
                    ref="savePaymentButton"
                    variant="outline-secondary"
                    size="sm"
                    class="ml-2"
                    @click="cancelClicked"
                    >Cancel</b-button
                  >
                  <!-- @vue-ignore -->
                  <b-button
                    pill
                    size="sm"
                    class="ml-2"
                    :variant="submitButton"
                    @click.prevent="submit"
                    :disabled="disableSubmit"
                    >Save payment method</b-button
                  >
                </b-button-group>
              </b-col>
            </b-row>
          </b-container>

          <b-container class="p-0 m-0" v-else>
            <!-- @vue-ignore -->
            <b-button
              ref="subscribeButton"
              @click="submit"
              :variant="submitButton"
              class="btn btn-block"
              :disabled="disableSubmit"
              ><b-spinner v-if="busy" small label="loading"></b-spinner>
              {{ buttonTitle }}</b-button
            >
            <label class="text-muted confirm-label"
              >By confirming your subscription, you allow Authentic Labs to
              charge your card for this payment and future payments in
              accordance with their terms</label
            >
          </b-container>
        </b-col>

        <template #overlay>
          <div class="text-center">
            <b-spinner variant="primary" label="Spinning"></b-spinner>
            <p class="payment-waiting">Getting payment information...</p>
          </div>
        </template>
      </b-overlay>
    </b-form-row>
  </b-form>
</template>

<script setup lang="ts">
import {
  StripeCheckout,
  StripeElementCard,
  StripeElementsPluginObject,
} from "@vue-stripe/vue-stripe";
import { Company, Product } from "@authentic-labs/vue-component-library";
import User from "../../models/User";
import { updateCompanyAdmin } from "../../services/AdminService";
import {
  getCustomerStatus,
  intent,
  savePayment,
  subscribe,
  updateAdditionalProducts,
  checkCoupon,
} from "@/services/PaymentService";
import PaymentMethod from "../../models/PaymentMethod";
import {
  getDefaultPayment,
  removeAdditionalProducts,
} from "../../services/PaymentService";
import { BButton } from "bootstrap-vue";
import { uploadFile } from "../../services/FileService";
import { createProduct, fetchProducts } from "../../services/ProductService";
import { computed, ref, inject, onMounted, markRaw } from "vue";
import { useStore } from "vuex";
import { useField, useForm } from "vee-validate";
import { object, string } from "yup";
import { ref as yupRef } from "yup";
import { useToast } from "vue-toastification";

const $store = useStore();
const emitter: any = inject("emitter");
const toast = useToast();

const props = defineProps({
  cardAction: String,
  onCancel: Function,
  subscriptionType: String,
  paymentFrequency: String,
  subscriptionChange: Boolean,
  newCard: Boolean,
});

const elementRef = ref<StripeElementsPluginObject>();
const couponButton = ref<BButton>();
const couponSpinner = ref<HTMLDivElement>();
const validationResult = ref<HTMLElement>();
const subscribeButton = ref<BButton>();

const company = computed(() => {
  return $store.getters.company;
});
const user = computed(() => {
  return $store.getters.authUser;
});
const product = computed(() => {
  return $store.getters.product;
});

const newCardClass = computed(() => {
  return props.newCard ? "new-card" : "";
});

const subscription = computed(() => {
  return $store.getters.subscription;
});

const placeHolderHeaderImage = computed(() => {
  return new URL("/src/assets/images/app/placeholder16x9.png", import.meta.url)
    .href;
});
const placeHolderLogoImage = computed(() => {
  return new URL("/src/assets/images/app/placeholder1x1.png", import.meta.url)
    .href;
});

const validationSchema = markRaw(
  object({
    user: object({
      name: string().required("Name is required field"),
    }),
  })
);
const { validate, errors, meta } = useForm({ validationSchema });
const userName = useField("user.name", validationSchema);

const cardStyle = {
  base: {
    iconColor: "red",
    lineHeight: "25px",
    marginBottom: "0px",
    color: "#32315E",
    fontWeight: 400,
    fontFamily: "'proximanova-regular', sans-serif",
    fontSize: "14.4px",

    "::placeholder": {
      color: "#495057",
    },
  },
};

let buttonTitle = ref("Subscribe");
let couponButtonTitle = ref("Apply code");
let busy = ref(false);
let validating = ref(false);
let validationFailed = ref(false);
let validationConfirmed = ref(false);
let intentToken = "";
let stripeToken: object = {};
let email = ref("");
let code = ref("");
let hasPaymentMethod = ref(false);
let paymentDetails = ref<Partial<PaymentMethod> | null>({});
let showOverlay = ref(true);
let priceId = "";
let disableSubmit = ref(false);
let disableCouponSubmit = ref(false);
let submitButton = ref("primary");
let validateButton = ref("outline-secondary");
const publishableKey = ref(import.meta.env.VITE_APP_STRIPE_TOKEN);
let hasName = ref(true);
let payLoad: Partial<Product> = {};

const confirmedButtonHTML = `<div class="confirmed-text">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-check-circle" viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                    <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"/>
                  </svg> <span class="confirmed-text-span">Subscription confirmed</span>
                </div>`;

onMounted(async () => {
  showOverlay.value = true;
  paymentDetails.value = null;
  if (props.cardAction !== "save") {
    paymentDetails.value = await getDefaultPayment();
  }
  if (!props.newCard) {
    setPriceId();
  }

  email = user.value.email;
  userName.value.value = user.value.name;
  if (paymentDetails.value === null) {
    hasPaymentMethod.value = false;
    loadIntent();
  } else {
    hasPaymentMethod.value = true;
    showOverlay.value = false;
  }
});

async function loadIntent() {
  intentToken = await intent();
  showOverlay.value = false;
}

function setPriceId() {
  if (
    props.subscriptionType === "basic" &&
    props.paymentFrequency === "monthly"
  ) {
    priceId = import.meta.env.VITE_APP_BASIC_MONTHLY;
  }
  if (
    props.subscriptionType === "pro" &&
    props.paymentFrequency === "monthly"
  ) {
    priceId = import.meta.env.VITE_APP_PRO_MONTHLY;
  }
  if (
    props.subscriptionType === "business" &&
    props.paymentFrequency === "monthly"
  ) {
    priceId = import.meta.env.VITE_APP_BUSINESS_MONTHLY;
  }
  if (
    props.subscriptionType === "enterprise" &&
    props.paymentFrequency === "monthly"
  ) {
    priceId = import.meta.env.VITE_APP_ENTERPRISE_MONTHLY;
  }
}

async function validateCoupon() {
  validating.value = true;
  disableCouponSubmit.value = true;
  couponButtonTitle.value = "Validating code...";
  const checkResponse = await checkCoupon(code.value);
  validating.value = false;
  couponButtonTitle.value = "";
  if (checkResponse === "valid") {
    validationConfirmed.value = true;
    validateButton.value = "success";
  } else {
    validationFailed.value = true;
    validateButton.value = "danger";
  }
}

function enterCoupon() {
  validateButton.value = "outline-secondary";
  couponButtonTitle.value = "Apply code";
  disableCouponSubmit.value = false;
  validating.value = false;
  validationFailed.value = false;
  validationConfirmed.value = false;
}

async function submit() {
  const valid = await validate();
  if(!valid) {
    return;
  };
  let subscribeResponse = "";
  disableSubmit.value = true;
  const currentTotalProducts =
    subscription.value.numberAddons + subscription.value.productNumber;
  if (paymentDetails.value) {
    const paymentDetailsUpdate = {
      paymentMethod: paymentDetails.value?.id,
      priceId: priceId,
      subscriptionType: props.subscriptionType,
      subscriptionChange: props.subscriptionChange,
      discountCode: code,
    };
    busy.value = true;
    buttonTitle.value = "Updating subscription...";
    subscribeResponse = await subscribe(paymentDetailsUpdate);
    await $store.dispatch("getSubscriptionStatus", { user: user });
    if (props.subscriptionChange) {
      await removeAdditionalProducts(paymentDetails.value);
      if (subscription.value.productNumber > currentTotalProducts) {
        const newProductCount =
          subscription.value.productNumber - currentTotalProducts;
        await createProducts(newProductCount);
      }
      paymentDetailsUpdate.priceId =
        import.meta.env.VITE_APP_ADDITIONAL_PRODUCT;
      // The following updates additional products if number of products is larger than what is included in the subscription.
      if (currentTotalProducts - subscription.value.productNumber > 0) {
        //@ts-ignore
        paymentDetailsUpdate["quantity"] =
          currentTotalProducts - subscription.value.productNumber;
        await updateAdditionalProducts(paymentDetailsUpdate);
      }
    }
    (subscribeButton as BButton).innerHTML = confirmedButtonHTML;
    busy.value = false;
    submitButton.value = "success";
    company.value.status = "Active";
    updateCompany();
  } else {
    // @ts-ignore //TODO check this
    const stripe = elementRef.value.stripe;
    const cardSetupResult = await stripe.confirmCardSetup(intentToken, {
      payment_method: {
        // @ts-ignore //TODO check this
        card: elementRef.value.element,
        billing_details: {
          name: email,
        },
      },
    });
    if (cardSetupResult.error || !hasName) {
      disableSubmit.value = false;
      return;
    }
    props.cardAction === "save"
      ? savePaymentCompany(cardSetupResult)
      : subscribeCompany(cardSetupResult);
  }
}

async function createProducts(quantity: number) {
  let message = " new products were successfully added.";
  if (quantity === 1) {
    message = " new product was successfully added.";
  }
  payLoad = {
    model: "",
    name: "Product name",
    status: "active",
  };
  const placeholderResponse = await setPlaceholderImages();
  payLoad.imageFile = { filesId: placeholderResponse.fileHeaderId };
  payLoad.appLogoFile = { filesId: placeholderResponse.fileLogoId };
  try {
    for (let i = 0; i < quantity; i++) {
      const products = await createProduct(company.value.companyId, payLoad);
      emitter.emit("newProductAdded", products); //TODO watch this not sure about race condition
    }
    toast.success(quantity + message);
  } catch (error) {
    toast.error("Something went wrong. Product was not added.");
    console.error("Error adding new products: " + error);
    return "error";
  }
  return "success";
}

async function setPlaceholderImages() {
  //set placeholder for header image
  const headerResponse = await fetch(placeHolderHeaderImage.value);
  const logoResponse = await fetch(placeHolderLogoImage.value);
  const headerBlob = await headerResponse.blob();
  const logoBlob = await logoResponse.blob();
  const headerFile = new File([headerBlob], "placeholder16x9.png", {
    type: headerBlob.type,
  });
  const logoFile = new File([logoBlob], "placeholder1x1.png", {
    type: logoBlob.type,
  });
  const fileHeader = await uploadFile(headerFile);
  const fileLogo = await uploadFile(logoFile);
  return { fileHeaderId: fileHeader.filesId, fileLogoId: fileLogo.filesId };
}

async function subscribeCompany(cardSetupResult: any) {
  busy.value = true;
  buttonTitle.value = "Confirming subscription...";
  const paymentDetails = {
    paymentMethod: cardSetupResult.setupIntent.payment_method,
    priceId: priceId,
    subscriptionType: props.subscriptionType,
    discountCode: code,
  };
  const subscribeResponse = await subscribe(paymentDetails);
  await $store.dispatch("getSubscriptionStatus", { user: user });
  const existingProducts = await fetchProducts(company.value.companyId!);
  const quantity = subscription.value.productNumber - existingProducts.length;
  if (quantity > 0 && subscription.value.productNumber !== 1000) {
    await createProducts(quantity);
  } 
  (subscribeButton as BButton).innerHTML = confirmedButtonHTML;
  busy.value = false;
  submitButton.value = "success";
  company.value.status = "Active";
  updateCompany();
  paymentResponse(subscribeResponse);
}

async function savePaymentCompany(cardSetupResult: any) {
  const savePaymentResponse = await savePayment({
    paymentMethod: cardSetupResult.setupIntent.payment_method,
  });
  if (props.newCard) {
    emitter.emit("billing-summary", "refresh");
  } else {
    updateCompany();
  }
  paymentResponse(savePaymentResponse);
}

function paymentResponse(status: string) {
  if (props.newCard) {
    toast.success("A payment method was successfully added");
  } else if (status === "success" && props.cardAction === "save") {
    toast.success("A payment method was successfully created");
    emitter.emit("subscription-modal");
  } else if (status === "success" && !props.cardAction) {
    toast.success("Successfully subscribed to " +
        titleCase(props.subscriptionType as string) +
        " content");
  } else {
    toast.error("There was an issue with creating a payment");
  }
}

function cancelClicked() {
  if (props.onCancel) {
    props.onCancel();
  }
}

function tokenCreated(token: any) {
  stripeToken = token;
}

function managePayments() {
  console.log(
    "...add the logic to move to payment managment and close current dialog"
  );
}

function titleCase(string: string) {
  return string[0].toUpperCase() + string.slice(1).toLowerCase();
}

async function updateCompany() {
  const companyUpdate = company;
  company.value.type = props.subscriptionType;
  try {
    await updateCompanyAdmin(company.value.companyId, company.value);
    toast.success("Company details for " +
        companyUpdate.value.companyName +
        " have been successfully update!")
    $store.dispatch("getCompany", company.value.companyId);
    setTimeout(() => emitter.emit("close-payment-modal"), 500);
    emitter.emit("billing-summary", "refresh");
  } catch {
    toast.error("Company update failed, please try again");
  }
}
</script>

<style scoped>
:deep .StripeElement {
  box-sizing: border-box;
  height: calc(1.6em + 0.75rem + 2px) !important;
  padding: 8px 12px !important;
  border: 1px solid #ced4da !important;
  border-radius: 0.5rem !important;
  background-color: white;
  box-shadow: none !important;
}

:deep .StripeElement--focus {
  color: #495057 !important;
  background-color: #fff !important;
  border-color: #a8c5f5 !important;
  outline: 0 !important;
  box-shadow: 0 0 0 0.2rem rgb(54 121 232 / 25%) !important;
}

:deep .StripeElement--is-error {
  color: red !important;
  border-color: red !important;
}

:deep #stripe-element-errors {
  color: red;
  font-size: 14px;
}

.coupon-button {
  border-radius: 0.5rem;
  width: 100%;
}

.spinner-border {
  vertical-align: sub !important;
  margin-right: 5px;
}

.btn.btn-success {
  transition-duration: 0.75s;
  animation-name: colorTransition;
}

@keyframes colorTransition {
  from {
    background-color: #3679e8 !important;
  }
  to {
    background-color: #38c172 !important;
  }
}

:deep .confirmed-text-span {
  margin-left: 0.25rem;
  display: inline-block;
  vertical-align: middle;
}

:deep .confirmed-text {
  justify-content: center;
  font-family: Arial, Helvetica, sans-serif;
  animation: fadeIn linear 0.75s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.new-card {
  /* margin-top: 125px; */
  margin-top: 115px;
}

.card-info {
  height: 37px;
  border-radius: 0.5rem;
  font-size: 14.4px;
  white-space: nowrap;
}

.card-info-text {
  flex-wrap: nowrap;
  padding-left: 1rem;
  padding-right: 1rem;
}

.default {
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.labels-names {
  font-size: 16px !important;
}
.labels {
  font-size: 16px;
}
.email {
  margin-top: -1.25rem;
}
.confirm-label {
  font-size: 12px;
  display: block;
  text-align: center;
}

#card-input.form-group {
  margin-bottom: 0.5rem;
}

:deep .col-form-label {
  padding-bottom: 0px;
}
.payment-waiting {
  white-space: nowrap;
  font-family: "proximanova-medium", sans-serif;
}
.pay-card {
  font-family: "proximanova-medium", sans-serif;
  font-size: 16px;
}
.spacer {
  margin-top: 2.25rem !important;
}

.invalid-feedback {
  font-size: 14px;
  margin-top: -8px;
}

.spinner-border {
  margin-right: 2px;
  vertical-align: sub;
  margin-bottom: 1px;
}
.bottom-space {
  margin-bottom: 1px;
}
</style>
