<template>
  <authentic-modal
    ref="subscriptionModal"
    id="subscription-modal"
    size="xl"
    :hideFooter="true"
  >
    <b-container class="select-payment">
      <!-- @vue-ignore -->
      <b-card no-body border-variant="0">
        <b-card-text class="subscription-title mb-0"
          >Welcome to your Real&reg; Brand Dashboard account!</b-card-text
        >
        <b-card-text class="subscription-subtitle"
          >Please choose a plan below to begin editing and previewing your
          content.</b-card-text
        >
      </b-card>
      <b-row class="choose">
        <RealPlanGroup />
      </b-row>
      <b-row>
        <b-col>
          <b-card-text class="text-muted pl-4 footnotes"
            >* Basic company categories: Environment, Manufacturing, Community,
            Diversity & Equity, Ingredients, Data Privacy and Accessibility.
          </b-card-text>
          <b-card-text class="text-muted pl-4 footnotes"
            >** Premium categories: Social Media Connect, Authorized Retailers,
            Promo Codes & Discounts, Careers & Recruitment, Product
            Registration, Topical, Leadership, News, and more.</b-card-text
          >
          <b-card-text class="text-muted pl-4 footnotes"
            >*** Serialized codes for product authentication available on the
            Pro and Business plans at an additional, but extremely reasonable,
            cost per product.</b-card-text
          >
        </b-col>
      </b-row>
    </b-container>
  </authentic-modal>
</template>

<script lang="ts">
import AuthenticModal from "./AuthenticModal.vue";
import RealPlanGroup from "./RealPlanGroup.vue";

import { Component, Vue, Ref } from "vue-facing-decorator";

@Component({
  components: {
    AuthenticModal,
    RealPlanGroup,
  },
})
export default class RealSubscriptions extends Vue {
  @Ref()
  subscriptionModal!: AuthenticModal;

  modalTitle = "Select a plan";

  created() {
    this.emitter.on("subscription-modal", () => {
      this.subscriptionModal.show();
    });
    this.emitter.on("subscription-modal-close", () => {
      this.subscriptionModal.hide();
    });
  }

  beforeUnmount() {
    this.emitter.off("subscription-modal");
    this.emitter.off("subscription-modal-close");
  }
}
</script>

<style scoped>
.subscription-title {
  font-family: "proximanova-medium", sans-serif;
  font-size: 32px;
}
.subscription-subtitle {
  font-size: 16px;
  margin-bottom: -20px !important;
}

.footnotes {
  margin-bottom: 5px;
  font-size: 12px;
}

.choose {
  margin-bottom: -20px !important;
}

.select-payment {
  height: 80vh !important;
  overflow-y: auto !important;
  overflow-x: hidden !important;
}
</style>
